import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SobreNos from "../../components/sobre-nos"
import BackgroundImage from "gatsby-background-image"
import { graphql, Link } from "gatsby"

const ServicoDomicilio = ({ data }) => {
  return (
    <Layout showHero={true}>
      <SEO title="Conserto De Ventiladores | Cedilar" />
      <SobreNos />
      <div className="container mx-auto justify-between">
        <h1 className="px-4 md:px-0 pb-2 pt-12 text-xl font-bold">
          Serviços à domicílio de Reparo de Eletrodomésticos na Grande Porto
          Alegre
        </h1>
        <p className="px-4 md:px-0 pb-6">
          Nem sempre é possível sair de casa para resolver problemas
          relacionados a reparos e manutenção de equipamentos, e na verdade,
          você necessariamente não precisa fazer isso, bastando ter uma equipe
          de assistência técnica especializada de qualidade em quem confiar,
          como a CeDilar.
        </p>
        <p className="px-4 md:px-0 pb-6">
          A{" "}
          <Link to="/" className="text-blue-600">
            Assistência Técnica CeDilar
          </Link>{" "}
          atua há 30 anos no mercado trabalhando com excelência e qualidade em
          todos os processos que desenvolve, sendo autorizada em manutenção e
          reparos de equipamentos das maiores marcas do mercado.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Em Porto Alegre e cidades ao redor você pode contar com o serviço a
          domicílio, ideal para você que não pode sair de casa ou se o seu
          equipamento precisa ser desinstalado para um possível reparo.
          Precisou? Só chamar!
        </p>
        <h2 className="px-4 md:px-0 pb-2 text-xl font-bold">
          Assistência técnica de qualidade
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Com a Assistência Técnica da CeDilar você tem qualidade e segurança,
          cuidando do seu equipamento sem precisar se preocupar com
          absolutamente nada, sabendo que seu produto está em boas mãos e que o
          melhor em assistência técnica será investido nele.
        </p>
        <p className="px-4 md:px-0 pb-6">
          A CeDilar atua há mais de 30 anos no mercado, tendo sua inauguração no
          dia 15 de maio de 1990, e desde então vem trazendo o que há de melhor
          em assistência técnica especializada para a região de Porto Alegre e
          cidades ao redor, promovendo assistência de qualidade com segurança.
        </p>
        <p className="px-4 md:px-0 pb-12">
          Ter com quem contar quando precisa em momentos de aperto quanto ao
          funcionamento de equipamentos e eletrodomésticos essenciais é algo que
          muitas pessoas procuram, e que você pode ter ao contatar a nossa
          empresa, sempre trazendo o que há de melhor ao atendimento a nossos
          clientes.
        </p>
      </div>
      <BackgroundImage
        Tag="section"
        fluid={data?.parqueFarroupilha?.childImageSharp?.fluid}
        className="bg-scroll h-64"
        alt="Parque Farroupilha Porto Alegre"
      />
      <div className="container mx-auto justify-between">
        <h2 className="px-4 md:px-0 pb-2 pt-12 text-xl font-bold">
          Atendimento rápido e a domicílio
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Um de nossos diferenciais é o atendimento rápido e a domicílio, onde
          você não precisa sair de casa para contar com uma assistência técnica
          de qualidade, bastando apenas fazer uma ligação para o (51) 3217-9658,
          indicar o equipamento com defeito, repassar as informações necessárias
          e aguardar até que cheguemos em sua residência.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Agora, caso você deseje por algum motivo se dirigir de forma
          presencial até algum dos nossos pontos de atendimento, o nosso
          endereço é Avenida Ipiranga, 1477, Azenha, Porto Alegre, RS.
        </p>
        <h2 className="px-4 md:px-0 pb-2 text-xl font-bold">
          Trabalho autorizado
        </h2>
        <p className="px-4 md:px-0 pb-6">
          A Assistência Técnica CeDilar oferta o que há de mais atual e completo
          em assistência técnica, garantindo que você como cliente possa se
          sentir seguro em repassar seus equipamentos para concertos ou reparos
          conosco.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Dessa forma, colaboramos para a manutenção da garantia de fábrica do
          seu equipamento, ofertando ainda a nossa própria garantia de 3 meses
          pelas peças originais em reparos maiores, lhe deixando totalmente
          seguro e ampliando a vida-útil do seu produto.{" "}
        </p>

        <p className="px-4 md:px-0 pb-12">
          Está precisando de alguma ajuda com reparos de equipamentos? Chame a
          Assistência Técnica CeDilar e garanta o que há de melhor no mercado!
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    parqueFarroupilha: file(
      relativePath: { eq: "Parque-Farroupilha-Porto-Alegre.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default ServicoDomicilio
